export const environment = {
  production: false,
  isLocal: false,
  name: 'DEV',
  auth2FA: false,
  frontendUrl: 'https://galeriekodl.dev.bckpp.com',
  baseAdminUrl: 'https://admin.galeriekodl.dev.bckpp.com',
  baseApiUrl: 'https://api.galeriekodl.dev.bckpp.com',
  webCdnUrl: 'https://web.cdn.dev.bckpp.com',
  sharedCdnUrl: 'https://shared.cdn.dev.bckpp.com'
};
